
const app = {
  // 全局状态
  state: {
    language: localStorage.getItem('language') || 'en',
    // staticHost: 'https://test-api-xinshijie.jjy-furit.com/',
    // staticHost: 'http://127.0.0.1:3000/',
    staticHost: 'https://gapi.amdchipworld.com/',
    newsId: '',
  },
  // getters是对数据的包装，例如对数据进行拼接，或者过滤
  getters: {
    language: state => state.language,
    //类似于计算属性
    // 增加的方法
  },
  // 如果我们需要更改store中的状态，一定要通过mutations来进行操作
  mutations: {
    CHANGE_NEWSID (state, id) {
      console.log('id', id)
      state.newsId = id
    },
    CHANGE_LANGUAGE (state, type) {
      console.log('type', type)
      localStorage.setItem('language', type)
      state.language = type
    }
  },

  // actions是我们定义的一些操作，正常情况下，我们很少会直接调用mutation方法来改变state
  actions: {

  }
}
export default app
